

































































































































































import { defineComponent } from '@vue/composition-api'
import { mapActions, mapGetters, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import TopBar from '@/components/organisms/o-top-bar.vue'
import { RawLocation } from 'vue-router'
import cancelSaveDialog from '@/mixins/cancelSaveDialog.js'
import { isReadonly } from '@/composables/UserGrant'

export default defineComponent({
  setup () {
    const readonly = () => isReadonly()
    return {
      readonly,
    }
  },
  name: 'userForm',
  components: {
    TopBar,
    CancelUpdateDialog: () =>
      import('@/components/cancel-update-dialog.vue'),
    ScrollTop: () => import('@/components/base/ScrollTop.vue'),
  },
  data () {
    return {
      title: this.$t('user.title_header'),
      errors: [],
      showPassword: false,
      originInitials: null,
      isLoading: false,
    }
  },
  async created () {
    await this.init()
  },
  mixins: [cancelSaveDialog],
  computed: {
    ...mapState('userGroupList', {
      listUserGroup: 'list',
      loading: 'loading',
    }),
    ...mapState('userForm', {
      isUserSubmitted: 'isSubmitted',
      isFormChanged: 'isChanged',
    }),
    ...mapGetters('navigation', {
      getLastSavedRoute: 'getLastSavedRoute',
    }),
    ...mapFields('userForm', [
      'user.email',
      'user.userGroups',
      'user.firstName',
      'user.lastName',
      'user.initials',
      'user.password',
    ]),
    idUser () {
      return this.$route.params.idUser || null
    },
  },
  methods: {
    ...mapActions('userGroupList', {
      loadUserGroup: 'load',
    }),
    ...mapActions('user', {
      sendSuccessMessage: 'sendSuccessMessage',
    }),
    ...mapActions('userForm', {
      save: 'save',
      reset: 'reset',
      loadUserById: 'loadById',
    }),
    async onSubmit () {
      const isValidatedForm = await (this as any).isValidatedForm(
        this.$refs.validationObserver,
      )
      if (isValidatedForm) {
        await this.save(this.user)
        this.sendSuccessMessage('user.form.saved')
        this.returnPrevious()
      }
    },
    returnPrevious () {
      if (this.getLastSavedRoute) {
        this.$router.push(this.getLastSavedRoute as RawLocation)
      } else {
        this.$router.push({ name: 'User List' })
      }
    },
    cancel () {
      this.returnPrevious()
    },
    async init () {
      this.reset()
      if (this.idUser) {
        this.isLoading = true
        const user = await this.loadUserById(this.idUser)
        this.originInitials = JSON.parse(JSON.stringify(user.initials))
        this.isLoading = false
      }
      this.loadUserGroup()
    },
    updateInitials (firstName: string, lastName: string) {
      if (!this.isLoading && !this.originInitials && (firstName || lastName)) {
        this.initials = (
          (firstName ? firstName.charAt(0) : '') +
          (lastName ? lastName.charAt(0) : '') +
          (lastName && lastName.length > 1 ? lastName.charAt(lastName.length - 1) : '')
        ).toUpperCase()
      }
    },
  },
  watch: {
    firstName: {
      handler (newValue: string) {
        this.updateInitials(newValue, this.lastName as string)
      },
    },
    lastName: {
      handler (newValue: string) {
        this.updateInitials(this.firstName as string, newValue)
      },
    },
  },
})
