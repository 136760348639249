var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TopBar',{attrs:{"title":_vm.title}},[_c('template',{slot:"actions"},[_c('v-btn',{staticClass:"mb-0 mr-4 px-4",attrs:{"outlined":""},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" "+_vm._s(_vm.$t("btn.cancel"))+" ")]),(!_vm.readonly())?_c('v-btn',{staticClass:"mb-0 px-4",attrs:{"form":"userForm","color":"primary","type":"submit","loading":_vm.isUserSubmitted}},[_vm._v(" "+_vm._s(_vm.$t("btn.save"))+" ")]):_vm._e()],1)],2),_c('v-container',[[_c('div',[_c('validation-observer',{ref:"validationObserver"},[_c('v-form',{staticClass:"pt-8",attrs:{"id":"userForm"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit()}}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"id":"user","color":"primary","inline":"","dark":_vm.$vuetify.theme.dark,"icon":"mdi-map-marker"}},[_c('template',{slot:"bottom"},[_c('span',{staticClass:"text-subtitle-1 pl-2"},[_vm._v(_vm._s(_vm.$t("form.required")))])]),_c('template',{slot:"title"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("user.form.title"))+" ")])],1),_c('v-col',{attrs:{"md":"12"}},[_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('user.form.email'),"rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('user.form.email'),"outlined":"","name":"email","readonly":_vm.readonly()},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}])}),_c('validation-provider',{attrs:{"name":_vm.$t('user.form.firstName'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('user.form.firstName'),"outlined":"","name":"firstName","readonly":_vm.readonly()},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})]}}])}),_c('validation-provider',{attrs:{"name":_vm.$t('user.form.lastName'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('user.form.lastName'),"outlined":"","name":"lastName","readonly":_vm.readonly()},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})]}}])}),_c('validation-provider',{attrs:{"name":_vm.$t('user.form.initials'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('user.form.initials'),"outlined":"","name":"initials","maxlength":"5","readonly":_vm.readonly()},model:{value:(_vm.initials),callback:function ($$v) {_vm.initials=$$v},expression:"initials"}})]}}])}),_c('validation-provider',{attrs:{"name":_vm.$t('user.form.password'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"error-messages":errors,"type":_vm.showPassword ? 'text' : 'password',"label":_vm.$t('user.form.password'),"outlined":"","name":"password","readonly":_vm.readonly()},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}])}),_c('validation-provider',{attrs:{"name":_vm.$t('user.form.userGroups'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"ml-3 mb-4 pt-0",attrs:{"multiple":"","chips":"","error-messages":errors,"items":_vm.listUserGroup,"item-text":"name","item-value":"@id","label":_vm.$t('user.form.userGroups'),"readonly":_vm.readonly()},model:{value:(_vm.userGroups),callback:function ($$v) {_vm.userGroups=$$v},expression:"userGroups"}})]}}])})],1)],1)],2)],1)],1),_c('ScrollTop'),_c('cancel-update-dialog',{attrs:{"saving":_vm.isUserSubmitted},on:{"cancel":function($event){return _vm.onCancel()},"save":function($event){return _vm.onSave()}}})],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }